import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { addGetParams, hasPermission, usePermissionContext, } from "components-care";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { GLOBAL_MODE_APPS, MY_APP_DOMAIN_PREFIX, StripePublishableKey, } from "../../constants";
import { getSubdomain, isSubdomain, normalizeCanDos } from "../../utils";
import * as Sentry from "@sentry/react";
import { getSession, getSessionExpire, getSessionRefreshToken, useAuthProviderContext, } from "./AuthProvider";
import getCurrentApp from "../../utils/getCurrentApp";
import { useIsGlobalMode } from "./SamedisPortal";
// Pages (main app)
const Dashboard = React.lazy(() => import("../TenantSpecific/Home"));
const InventoriesCRUD = React.lazy(() => import("../TenantSpecific/Devices/Data/Inventories"));
const EventIssuesCRUD = React.lazy(() => import("../TenantSpecific/Devices/Tasks/Issues"));
const IncidentsCRUD = React.lazy(() => import("../TenantSpecific/Devices/Requests/Incidents"));
const BriefedEmployeeDevices = React.lazy(() => import("../TenantSpecific/Training/EmployeeReport/BriefedEmployeeDevices"));
const TenantEdit = React.lazy(() => import("../TenantSpecific/Facility/Facility/FacilityData"));
const SubscriptionPlan = React.lazy(() => import("../TenantSpecific/Facility/Billing/SubscriptionPlan"));
const ContactsTenant = React.lazy(() => import("../TenantSpecific/Contacts/Contacts/ContactsTenant"));
const TeamsCRUD = React.lazy(() => import("../TenantSpecific/Training/Groups/TrainingGroups"));
const DepartmentsCRUD = React.lazy(() => import("../TenantSpecific/Facility/Departments/Departments"));
const DeviceLocationsCRUD = React.lazy(() => import("../TenantSpecific/Facility/DeviceLocations/DeviceLocations"));
const StaffCRUD = React.lazy(() => import("../TenantSpecific/Contacts/Employees/Employees"));
const DirectoryCRUD = React.lazy(() => import("../TenantSpecific/Contacts/Directory/Directory"));
const TrainingCRUD = React.lazy(() => import("../TenantSpecific/Training/Tasks/TrainingTasks"));
const BriefingDevicesGrid = React.lazy(() => import("../TenantSpecific/Training/DeviceReport/DeviceTrainings"));
const BriefingMatrix = React.lazy(() => import("../TenantSpecific/Training/DeviceEmployeeMatrix/TrainingMatrix"));
const TrainingImportApproval = React.lazy(() => import("../TenantSpecific/Training/Shared/TrainingImportApproval"));
const AccessManagement = React.lazy(() => import("../TenantSpecific/Facility/AccessManagement/AccessManagement"));
const GlobalDeviceCatalog = React.lazy(() => import("../TenantSpecific/Devices/Catalog/GlobalDeviceCatalog"));
const FacilityContentCRUD = React.lazy(() => import("../TenantSpecific/Facility/Content/FacilityContent"));
const TenantDeviceModelsCRUD = React.lazy(() => import("../TenantSpecific/Facility/DeviceModels/FacilityDeviceModels"));
const TenantDeviceTypesCRUD = React.lazy(() => import("../TenantSpecific/Facility/DeviceTypes/FacilityDeviceTypes"));
// Pages (bi.samedis.care)
const FacilityLocationsReport = React.lazy(() => import("../TenantSpecific/Reports/FacilityLocations/FacilityLocations"));
const RegulatoryInventoryReport = React.lazy(() => import("../TenantSpecific/Reports/RegulatoryInventory/RegulatoryInventoryReport"));
const DeviceAuditReport = React.lazy(() => import("../TenantSpecific/Reports/AuditReport/AuditReport"));
const InventoryDashboard = React.lazy(() => import("../TenantSpecific/Reports/InventoryStatistics/InventoryDashboard"));
const IssuesDashboard = React.lazy(() => import("../TenantSpecific/Reports/DeviceTaskStatistics/IssuesDashboard"));
const TrainingsDashboard = React.lazy(() => import("../TenantSpecific/Reports/TrainingStatistics/TrainingsDashboard"));
const MtszCustomerReport = React.lazy(() => import("../TenantSpecific/Reports/MtszCustomerReport/CustomerReport"));
const MedworxCustomerReport = React.lazy(() => import("../TenantSpecific/Reports/MedworxCustomerReport/CustomerReport"));
// Pages (mdm.samedis.care)
const TenantOverview = React.lazy(() => import("../TenantSpecific/MasterDataManagement/TenantManagement/TenantOverview"));
const DeviceModelsMdm = React.lazy(() => import("../TenantSpecific/MasterDataManagement/CatalogModels/DeviceModelsMdm"));
const DeviceModelsPublishRequested = React.lazy(() => import("../TenantSpecific/MasterDataManagement/PublishCatalog/DeviceModelsPublishRequested"));
const DeviceTypeMdm = React.lazy(() => import("../TenantSpecific/MasterDataManagement/CatalogTypes/DeviceTypeMdm"));
const InventoryDeviceModelReview = React.lazy(() => import("../TenantSpecific/MasterDataManagement/InventoryReview/InventoryDeviceModelReview"));
const DeviceTags = React.lazy(() => import("../TenantSpecific/MasterDataManagement/CatalogTags/DeviceTags"));
const FacilityContentMdm = React.lazy(() => import("../TenantSpecific/MasterDataManagement/Contents/FacilityContentsMdm"));
const SecurityMessageMdm = React.lazy(() => import("../TenantSpecific/MasterDataManagement/SecurityMessages/SecurityMessageMdm"));
const ContactsMdm = React.lazy(() => import("../TenantSpecific/MasterDataManagement/PublicContacts/ContactsMdm"));
const NotificationBroadcast = React.lazy(() => import("../TenantSpecific/MasterDataManagement/NotificationBroadcast/NotificationBroadcast"));
// Pages (my.samedis.care)
const MyDashboard = React.lazy(() => import("../MySamedis/MyDashboard"));
const MyUsedDevices = React.lazy(() => import("../MySamedis/Devices/Devices"));
const MyIncidents = React.lazy(() => import("../MySamedis/Devices/Incidents"));
const MyFacilities = React.lazy(() => import("../MySamedis/Devices/Facilities"));
const MyScheduledTrainings = React.lazy(() => import("../MySamedis/Trainings/Scheduled"));
const MyPendingRoutingSlips = React.lazy(() => import("../MySamedis/Trainings/RoutingSlips"));
const MyTrainingDocumentation = React.lazy(() => import("../MySamedis/Trainings/Document"));
const MyTrainedDevices = React.lazy(() => import("../MySamedis/Trainings/Devices"));
// Pages (enterprise.samedis.care)
const EnterpriseDashboard = React.lazy(() => import("../TenantSpecific/Enterprise/Dashboard/EnterpriseDashboard"));
const EnterpriseDevices = React.lazy(() => import("../TenantSpecific/Enterprise/ClientDevices/Devices"));
const EnterpriseTasks = React.lazy(() => import("../TenantSpecific/Enterprise/ClientTasks/Tasks"));
const EnterpriseRequests = React.lazy(() => import("../TenantSpecific/Enterprise/ClientRequests/Requests"));
const EnterpriseFacilities = React.lazy(() => import("../TenantSpecific/Enterprise/ClientList/Facilities"));
const EnterpriseContacts = React.lazy(() => import("../TenantSpecific/Enterprise/Contacts/Contacts"));
// Dev Mode Pages
const DevOfflineMode = React.lazy(() => import("../DevMode/OfflineMode"));
const DevPlayground = React.lazy(() => import("../DevMode/Playground"));
const DEV_MODE = localStorage.getItem("SAMEDIS_DEV_MODE") === "true";
// routes do not include tenant ID despite name
const MySamedisRoutes = [
    {
        title: "my.home",
        route: "/",
        permissions: null,
        pageComponent: MyDashboard,
    },
    {
        title: "my.devices.root",
        permissions: null,
        pageComponent: null,
        children: [
            {
                title: "my.devices.used_devices",
                route: "/devices/used",
                permissions: null,
                pageComponent: MyUsedDevices,
            },
            {
                title: "my.devices.global-device-catalog",
                route: "/devices/global-device-catalog",
                permissions: null,
                pageComponent: GlobalDeviceCatalog,
            },
        ],
    },
    {
        title: "my.trainings.root",
        permissions: null,
        pageComponent: null,
        children: [
            {
                title: "my.trainings.device_trainings",
                route: "/trainings/overview",
                permissions: null,
                pageComponent: MyTrainedDevices,
            },
            {
                title: "my.trainings.scheduled_trainings",
                route: "/trainings/scheduled",
                permissions: null,
                pageComponent: MyScheduledTrainings,
            },
            {
                title: "my.trainings.pending_routing_slips",
                route: "/trainings/pending-routing-slips",
                permissions: null,
                pageComponent: MyPendingRoutingSlips,
            },
            {
                title: "my.trainings.document_training",
                route: "/trainings/document",
                permissions: null,
                pageComponent: MyTrainingDocumentation,
            },
        ],
    },
    {
        title: "my.facilities.root",
        permissions: null,
        pageComponent: null,
        children: [
            {
                title: "my.facilities.facilities",
                route: "/devices/facilities",
                permissions: null,
                pageComponent: MyFacilities,
            },
            {
                title: "my.facilities.incidents",
                route: "/devices/incidents",
                permissions: null,
                pageComponent: MyIncidents,
            },
        ],
    },
];
const AppTenantRoutes = [
    {
        title: "main.start",
        route: "/",
        permissions: null,
        pageComponent: Dashboard,
    },
    {
        title: "main.devices.root",
        permissions: null,
        pageComponent: null,
        children: [
            {
                title: "main.devices.inventory",
                route: "/devices/inventory",
                permissions: "inventories.reader",
                pageComponent: InventoriesCRUD,
            },
            {
                title: "main.devices.tasks",
                route: "/devices/tasks",
                permissions: "issues.reader",
                pageComponent: EventIssuesCRUD,
            },
            {
                title: "main.devices.requests",
                route: "/devices/requests",
                permissions: ["incidents.dispatcher", "incidents.supporter"],
                pageComponent: IncidentsCRUD,
            },
            {
                title: "main.devices.catalog",
                route: "/devices/catalog",
                permissions: "global-device-catalog.reader",
                pageComponent: GlobalDeviceCatalog,
            },
        ],
    },
    {
        title: "main.trainings.root",
        permissions: null,
        pageComponent: null,
        children: [
            {
                title: "main.trainings.tasks",
                route: "/trainings/tasks",
                permissions: "briefings.reader",
                pageComponent: TrainingCRUD,
            },
            {
                title: "main.trainings.shared",
                route: "/trainings/shared",
                permissions: "briefings.approve",
                pageComponent: TrainingImportApproval,
            },
            {
                title: "main.trainings.groups",
                route: "/trainings/groups",
                permissions: "teams.reader",
                pageComponent: TeamsCRUD,
            },
            {
                title: "main.trainings.devices",
                route: "/trainings/devices",
                permissions: "report-briefings.consumer",
                pageComponent: BriefingDevicesGrid,
            },
            {
                title: "main.trainings.employees",
                route: "/trainings/employees",
                permissions: "report-briefed-employee-devices.consumer",
                pageComponent: BriefedEmployeeDevices,
            },
            {
                title: "main.trainings.matrix",
                route: "/trainings/matrix",
                permissions: "report-briefed-employee-devices.consumer",
                pageComponent: BriefingMatrix,
            },
        ],
    },
    {
        title: "main.reports.root",
        permissions: null,
        pageComponent: null,
        children: [
            {
                title: "main.reports.facility-locations",
                route: "/reports/facility-locations",
                permissions: "bi.consumer",
                pageComponent: FacilityLocationsReport,
            },
            {
                title: "main.reports.audit",
                route: "/reports/audit",
                permissions: "bi.consumer+issues.reader",
                pageComponent: DeviceAuditReport,
            },
            {
                title: "main.reports.regulatory-inventory",
                route: "/reports/regulatory-inventory",
                permissions: "bi.consumer+inventories.reader",
                pageComponent: RegulatoryInventoryReport,
            },
            {
                title: "main.reports.inventory-stats",
                route: "/reports/inventory-stats",
                permissions: "bi.consumer",
                pageComponent: InventoryDashboard,
            },
            {
                title: "main.reports.device-tasks-stats",
                route: "/reports/device-tasks-stats",
                permissions: "bi.consumer",
                pageComponent: IssuesDashboard,
            },
            {
                title: "main.reports.training-stats",
                route: "/reports/training-stats",
                permissions: "bi.consumer",
                pageComponent: TrainingsDashboard,
            },
            {
                title: "main.reports.mtsz.customer_report",
                route: "/reports/mtsz/customers",
                permissions: "bi.consumer+bi-mtszreports.consumer",
                pageComponent: MtszCustomerReport,
            },
            {
                title: "main.reports.medworx.customer_report",
                route: "/reports/medworx/customers",
                permissions: "bi.consumer+bi-medworxreports.consumer",
                pageComponent: MedworxCustomerReport,
            },
        ],
    },
    {
        title: "main.contacts.root",
        permissions: null,
        pageComponent: null,
        children: [
            {
                title: "main.contacts.employees",
                route: "/contacts/employees",
                permissions: "staff.reader",
                pageComponent: StaffCRUD,
            },
            {
                title: "main.contacts.directory",
                route: "/contacts/directory",
                permissions: "directories.reader",
                pageComponent: DirectoryCRUD,
            },
            {
                title: "main.contacts.contacts",
                route: "/contacts/contacts",
                permissions: "contacts.reader",
                pageComponent: ContactsTenant,
            },
        ],
    },
    {
        title: "main.facility.root",
        permissions: null,
        pageComponent: null,
        children: [
            {
                title: "main.facility.device-locations",
                route: "/facility/device-locations",
                permissions: "device-locations.reader",
                pageComponent: DeviceLocationsCRUD,
            },
            {
                title: "main.facility.departments",
                route: "/facility/departments",
                permissions: "departments.reader",
                pageComponent: DepartmentsCRUD,
            },
            {
                title: "main.facility.access-management",
                route: "/facility/access-management",
                permissions: "access-control.reader",
                pageComponent: AccessManagement,
            },
            {
                title: "main.facility.content",
                route: "/facility/content",
                permissions: ["facility-contents.reader", "facility-contents.writer"],
                pageComponent: FacilityContentCRUD,
            },
            {
                title: "main.facility.device-types",
                route: "/facility/device-types",
                permissions: ["type-catalogs.reader", "type-catalogs.tenant-reader"],
                pageComponent: TenantDeviceTypesCRUD,
            },
            {
                title: "main.facility.device-models",
                route: "/facility/device-models",
                permissions: ["catalogs.reader", "catalogs.tenant-reader"],
                pageComponent: TenantDeviceModelsCRUD,
            },
            {
                title: "main.facility.data",
                route: "/facility/data",
                permissions: "tenant.admin",
                pageComponent: TenantEdit,
            },
            {
                title: "main.facility.billing",
                route: "/facility/billing",
                permissions: StripePublishableKey
                    ? "billing.admin"
                    : "billing.disabled",
                pageComponent: SubscriptionPlan,
            },
        ],
    },
    {
        title: "main.enterprise.root",
        permissions: null,
        pageComponent: null,
        children: [
            {
                title: "main.enterprise.dashboard",
                route: "/enterprise/dashboard",
                permissions: "enterprise.subscriber+dashboard.reader",
                pageComponent: EnterpriseDashboard,
            },
            {
                title: "main.enterprise.client-devices",
                route: "/enterprise/client-devices",
                permissions: "enterprise.subscriber+inventories.reader",
                pageComponent: EnterpriseDevices,
            },
            {
                title: "main.enterprise.client-tasks",
                route: "/enterprise/client-tasks",
                permissions: "enterprise.subscriber+issues.reader",
                pageComponent: EnterpriseTasks,
            },
            {
                title: "main.enterprise.client-requests",
                route: "/enterprise/client-requests",
                permissions: [
                    "enterprise.subscriber+incidents.dispatcher",
                    "enterprise.subscriber+incidents.supporter",
                ],
                pageComponent: EnterpriseRequests,
            },
            {
                title: "main.enterprise.clients",
                route: "/enterprise/clients",
                permissions: "enterprise.subscriber",
                pageComponent: EnterpriseFacilities,
            },
            {
                title: "main.enterprise.contacts",
                route: "/enterprise/contacts",
                permissions: "enterprise.subscriber+contacts.reader",
                pageComponent: EnterpriseContacts,
            },
        ],
    },
    {
        title: "main.mdm.root",
        permissions: null,
        pageComponent: null,
        children: [
            {
                title: "main.mdm.facilities",
                route: "/mdm/facilities",
                permissions: "tenant-admin-overview.reader",
                pageComponent: TenantOverview,
            },
            {
                title: "main.mdm.security-messages",
                route: "/mdm/security-messages",
                permissions: [
                    "security-messages.mdm-admin",
                    "security-messages.mdm-user",
                ],
                pageComponent: SecurityMessageMdm,
            },
            {
                title: "main.mdm.notifications",
                route: "/mdm/notifications",
                permissions: "notifications.reader",
                pageComponent: NotificationBroadcast,
            },
            {
                title: "main.mdm.contacts",
                route: "/mdm/contacts",
                permissions: ["contacts.mdm-admin", "contacts.mdm-user"],
                pageComponent: ContactsMdm,
            },
            {
                title: "main.mdm.catalog-tags",
                route: "/mdm/catalog-tags",
                permissions: ["type-catalogs.mdm-admin"],
                pageComponent: DeviceTags,
            },
            {
                title: "main.mdm.catalog-types",
                route: "/mdm/catalog-types",
                permissions: ["type-catalogs.mdm-admin", "type-catalogs.mdm-user"],
                pageComponent: DeviceTypeMdm,
            },
            {
                title: "main.mdm.catalog-models",
                route: "/mdm/catalog-models",
                permissions: ["catalogs.mdm-admin", "catalogs.mdm-user"],
                pageComponent: DeviceModelsMdm,
            },
            {
                title: "main.mdm.contents",
                route: "/mdm/contents",
                permissions: DEV_MODE
                    ? ["catalogs.mdm-admin", "catalogs.mdm-user"]
                    : false,
                pageComponent: FacilityContentMdm,
            },
            {
                title: "main.mdm.inventory-review",
                route: "/mdm/inventory-review",
                permissions: ["inventories.mdm-reviewer"],
                pageComponent: InventoryDeviceModelReview,
            },
            {
                title: "main.mdm.publish-catalog",
                route: "/mdm/publish-catalog",
                permissions: ["catalogs.mdm-admin"],
                pageComponent: DeviceModelsPublishRequested,
            },
        ],
    },
];
export const openApp = (evt, aux, app) => {
    const openInNewTab = aux || evt.ctrlKey || evt.metaKey || evt.shiftKey;
    // parse tenant from URL, should never be null
    let tenant = GLOBAL_MODE_APPS.includes(app)
        ? null
        : window.location.pathname.startsWith("/tenant/")
            ? window.location.pathname.split("/")[2]
            : null;
    if (!(GLOBAL_MODE_APPS.includes(getCurrentApp()) ||
        GLOBAL_MODE_APPS.includes(app)) &&
        (!tenant || !/[0-9a-fA-F]{16}/.test(tenant))) {
        // invalid tenant and not on my.samedis.care?
        Sentry.captureException(new Error(`Invalid tenant parsed from URL ${window.location.pathname} => ${tenant ?? "null"}`));
        tenant = null;
    }
    const targetDomain = getSubdomain(app === "main" ? null : app);
    const targetUrl = addGetParams(`${window.location.protocol}//${targetDomain}:${window.location.port}/authenticated`, {
        token: getSession(),
        token_expire: getSessionExpire()?.toISOString(),
        refresh_token: getSessionRefreshToken(),
        slim: sessionStorage.getItem("slim") ?? "false",
        lang: i18n.language,
        redirect_path: tenant ? `/tenant/${tenant}` : undefined,
    }).replace("?", "#");
    if (openInNewTab)
        window.open(targetUrl, "_blank");
    else
        window.location.href = targetUrl;
};
const devModeEntry = {
    title: "dev.root",
    permissions: DEV_MODE ? null : false,
    pageComponent: null,
    children: [
        {
            title: "dev.offline-mode",
            permissions: null,
            route: "/dev/offline-mode",
            pageComponent: DevOfflineMode,
        },
        {
            title: "dev.playground",
            permissions: null,
            route: "/dev/playground",
            pageComponent: DevPlayground,
        },
    ],
};
export const RawTenantRoutes = [
    ...(isSubdomain(MY_APP_DOMAIN_PREFIX) ? MySamedisRoutes : AppTenantRoutes),
    devModeEntry,
];
const TenantRoutesContext = React.createContext(undefined);
export const useTenantRoutesContext = () => {
    const ctx = useContext(TenantRoutesContext);
    if (!ctx)
        throw new Error("Tenant routes context not set");
    return ctx;
};
const convRawRoute = (t, permissions, route) => {
    const children = route.children?.map((entry) => convRawRoute(t, permissions, entry));
    return {
        ...route,
        title: t(route.title),
        shouldRender: hasPermission(permissions, route.permissions) &&
            (!children ||
                children.filter((child) => child.shouldRender).length > 0) &&
            !route.hideFromMenu,
        children,
    };
};
/**
 * Permission handler for cross-tenant routes
 * @param props
 * @constructor
 */
const TenantRoutesProvider = (props) => {
    const [routes, setRoutes] = useState([]);
    const [t] = useTranslation("menu");
    const [userPermissions] = usePermissionContext();
    const authCtx = useAuthProviderContext();
    const isGlobal = useIsGlobalMode();
    useEffect(() => {
        const allTenantPermissions = () => [
            ...userPermissions,
            ...authCtx.current_user.tenants
                .map((tenant) => normalizeCanDos(tenant.candos))
                .flat(),
        ];
        const permissions = isGlobal ? allTenantPermissions() : userPermissions;
        setRoutes(RawTenantRoutes.map((entry) => convRawRoute(t, permissions, entry)));
    }, [userPermissions, authCtx.current_user.tenants, t, isGlobal]);
    return (_jsx(TenantRoutesContext.Provider, { value: routes, children: props.children }));
};
export default React.memo(TenantRoutesProvider);
